import React from 'react';

import CallToAction      from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import TeamMembers       from '@interness/web-core/src/components/modules/TeamMembers';
import RandomBrandHeaderImages
                         from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import Separator         from '@interness/web-core/src/components/structure/Separator/Separator';
import ResponsiveIFrame  from '@interness/web-core/src/components/structure/iFrame/iFrame';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import Link              from '@interness/web-core/src/components/elements/Link/Link';
import Button            from '@interness/web-core/src/components/elements/Button/Button';

function IndexPage(props) {
  return (
    <>
      <RandomBrandHeaderImages count={3}/>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading subtitle="Herzlich Willkommen">Trauringstudio Solingen</Heading>
          <span style={{ textAlign: 'justify' }}>
            <p>Liebe Brautpaare!</p>
            <p>Herzlich willkommen im Trauringstudio Solingen!</p>
            <p>Wir möchten uns gerne vorstellen.</p>
            <p>Ihr findet unser Trauringstudio im Herzen der Klingenstadt Solingen, mitten im Bergischen Land.</p>
            <p>Unsere vielseitige Auswahl an Trauringen, Eheringen, Antragsringen, Verlobungsringen und Partnerringen präsentieren wir Euch gerne in unseren hellen Räumen, die separat über unserem Juweliergeschäft liegen. Hier könnt Ihr entspannt, unbehelligt und in Ruhe Eure individuelle Beratung genießen. Im wahrsten Sinne des Wortes über der normalen Alltagshektik..</p>
            <p>In entspannter und ungezwungener Atmosphäre entwerfen wir im Gespräch mit Euch Eure speziellen Trauringe. Neben unserer Erfahrung und unserem Fachwissen nutzen wir hierzu alle zur Verfügung stehenden Tools, von Musterringen bis zum Online-Konfigurator, um im herzlichen Dialog mit Euch Eure Ideen Gestalt annehmen zu lassen.</p>
            <p>Wir begleiten Euch auf dem Weg zu Eurer Hochzeit!</p>
            <p>Bis bald ins Solingen.<br/>Euer Team vom Trauringstudio</p>
          </span>
          <div style={{ textAlign: 'center' }}>
            <h3>Besuchen Sie uns auch bei Facebook oder Instagram!</h3>
            <Button style={{ marginRight: '10px' }} as={Link} external
                    to={'https://www.instagram.com/trauringstudio_solingen/'}>Instagram</Button>
            <Button as={Link} external to={'https://www.facebook.com/trauringstudio.solingen'}>Facebook</Button>
          </div>
        </section>
        <Spacer/>
        <LiveAnnouncements/>
        <Separator/>
        <section>
          <h2 style={{ textAlign: 'center' }}>Terminvereinbarung</h2>
          <p>
            Liebe Brautpaare, <br/>
            für eine Trauringberatung bitten wir Euch um eine Terminvereinbarung. Bitte telefonisch unter 0212/204654
            oder per Mail an info@juwelier-leiber.de. <br/>
            Gerne könnt Ihr auch hier direkt online Euren Termin buchen:
            <div style={{ textAlign: 'center' }}>
              <Button primary as={Link} to="https://calendly.com/trauringstudio-solingen">Termin buchen</Button>
            </div>
          </p>
        </section>
        <Spacer/>
        <section>
          <h2 style={{ textAlign: 'center' }}>So könnte Euer Besuch in unserem Trauringstudio aussehen</h2>
          <ResponsiveIFrame maxHeight={50} src={'https://www.youtube-nocookie.com/embed/CPlYPPfZKjY'}
                            title={'YoutubeVideo'} type="youtube"/>
        </section>
        <Separator/>
        <section>
          <TeamMembers/>
        </section>
        <Separator/>
        <section>
          <h2 style={{ textAlign: 'center' }}>Zertifizierte Lieferanten</h2>
          <p>Unsere Trauringlieferanten EGF und Rubin sind Mitglieder im „Reponsible Jewellery Council“. Der "RJC" ist
            ein internationaler Zusammenschluß von Unternehmen aus der Gold- und Juwelierswarenbranche.
            Zertifizierte Firmen garantieren in den Bereichen Unternehmensethik, Einhaltung der Menschenrechte,
            Umweltverantwortung und Managementsysteme, die Einhaltung und Förderung verantwortungsvoller
            Unternehmenspraxis.</p>

          <b>Erfahrt mehr auf den Seiten der Lieferanten:</b><br/>
          <Link to="http://www.egf.biz/zertifizierung/zertifizierung.php">EGF Zertifizierung</Link> <br/>
          <Link to="http://www.rubin-trauringe.de/ueber_rubin/firmenpolitik">Rubin Zertifizierung</Link>
        </section>
        <Spacer/>
        <div style={{ textAlign: 'center' }}>
          <h3>Wir empfehlen eine Terminvereinbarung zu Ihrem persönlichen Beratungsgespräch</h3>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;
