import React, { useState, useEffect } from 'react';

import Wrapper                       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import Heading                       from '@interness/web-core/src/components/text/Heading/Heading';

const DerTrauringJuwelier = () => {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState(null);
  const [list, setList] = useState(null);
  //
  useEffect(() => {
    fetchContent()
  }, [])

  const fetchContent = async () => {
    setLoading(true);
    const text = await (await fetch('https://haendler.dertrauringjuwelier.de/dtj-linkseite/juwelier-text.html')).text()
    const list = await (await fetch('https://haendler.dertrauringjuwelier.de/dtj-linkseite/juwelier-liste.html')).text()
    await setText(text);
    await setList(list);
    setLoading(false);
  }

  return (
    <Wrapper>
      <Heading subtitle='Partner'>Der Trauring Juwelier</Heading>
      {!loading
        ? <>
          <div id='TRJPartner' dangerouslySetInnerHTML={{ __html: text }}/>
          <div id='TRJPartnerliste' dangerouslySetInnerHTML={{ __html: list }}/>
        </>
        : <SpinnerContainer><Spinner/></SpinnerContainer>
      }
    </Wrapper>
  )
}

export default DerTrauringJuwelier;