import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import { Container, Row, Col } from 'react-grid-system';
import RandomBrandHeaderImages from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import MoreExpander from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const Separator = makeShortcode("Separator");
const Spacer = makeShortcode("Spacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <RandomBrandHeaderImages mdxType="RandomBrandHeaderImages" />
    <Wrapper mdxType="Wrapper">
      <hr></hr>
      <h1>{`Verlobungsringe`}</h1>
      <div style={{
        textAlign: 'center'
      }}>
        <h2 {...{
          "id": "glauben-sie-ihre-freundin-wartet-ewig"
        }}>{`Glauben Sie, Ihre Freundin wartet ewig?`}</h2>
        <p>{`Der Verlobung gehen die Fragen aller Fragen voraus. Will ich sie? Will sie mich? Passen wir wirklich bis an unser Lebensende zusammen? ... Fragen über Fragen, die wir alle kennen, und dann kommt der große Moment: "Willst Du mich heiraten?" Es gibt kein emotionaleres Geschenk zu diesem Anlass als einen "unvergänglichen" Verlobungsdiamanten. Meistens in der Form eines Ringes, aber wir können uns auch andere Diamantschmuckstücke dafür vorstellen. Wir bieten ein ausgewogenes Angebot an Verlobungsschmuck und unsere sympathisch-seriöse Beratung. Wir freuen uns, wenn Sie uns Ihr Vertrauen schenken.`}</p>
        <h2 {...{
          "id": "mit-dem-verlobungsring-zeigt-sie-der-ganzen-welt-dass-sie-ja-gesagt-hat"
        }}>{`Mit dem Verlobungsring zeigt sie der ganzen Welt, dass sie "Ja" gesagt hat.`}</h2>
      </div>
      <hr></hr>
      <RandomBrandHeaderImages count={3} mdxType="RandomBrandHeaderImages" />
      <hr></hr>
      <Container fluid mdxType="Container">
        <Row align='center' style={{
          textAlign: 'center'
        }} mdxType="Row">
  <Col md={6} mdxType="Col">
    <b>Website: </b><a href='http://www.rubin-trauringe.de/verlobungsringe/' title='Rubin Verlobungsringe' target='_blank' rel='noopener noreferrer'>Rubin</a>
  </Col>
  <Col md={6} mdxType="Col">
    <b>Website: </b><a href='https://www.saintmaurice.de/verlobungsringe.html' title='Saint Maurice Verlobungsringe' target='_blank' rel='noopener noreferrer'>Saint Maurice</a>
  </Col>
        </Row>
      </Container>
      <Separator mdxType="Separator" />
      <Container fluid mdxType="Container">
        <Row mdxType="Row">
  <Col md={4} mdxType="Col">
    <img src='//interness.de/customer/logo/gia-logo-240-80.png' alt='GIA' />
  </Col>
  <Col md={8} mdxType="Col">
    <p>
      GIA ZERTIFIKAT BEI BRILLANTEN AB 0,30CT
      Alle Verlobunsgringe von Rubin ab 0,30ct erhalten Sie bei uns mit einem GIA Zertifikat,
      mit den Eigenschaften Exzellent und Fluoreszenz keine.
    </p>
    <MoreExpander title='Was ist ein GIA-Zertifikat für Diamanten? - mehr erfahren ...' mdxType="MoreExpander">
      <Spacer height={20} mdxType="Spacer" />
      <p>
        Ein Zertifikat (auch Expertise genannt) ist ein Bewertungsgutachten, welches durch ein unabhängiges
        anerkanntes gemmologisches Institut erstellt wird. Es ist die Geburtsurkunde des Diamanten und gibt
        Auskunft über den Schliff, das Karat-Gewicht, die Farbe, Reinheit, Politur, Symmetrie, Fluoreszenz
        und die äußeren Merkmale des Diamanten. Wir empfehlen Ihnen ausschließlich GIA-Zertifikate, weil
        dieses Institut besonders strenge Maßstäbe bei der Bewertung der Diamanten anwendet und weil es
        international anerkannt ist. Es ist weltweit das größte und älteste unabhängige Institut, dessen
        Zertifikate sich durch Ihre Verlässlichkeit auszeichnen. Diamanten mit einem GIA-Zertifikat haben
        daher die größte Wertbeständigkeit.
        Alle von uns angebotenen Verlobungsringe der Marke Rubin ab einer Steingrösse von 0,30ct erhalten
        Sie mit einem GIA-Zertifikat, mit den Eigenschaften Schliff + Symmetrie + Politur = exzellent. Alle
        Brillanten weisen keine Fluoreszenz auf und sind mit einer Laserinschrift versehen. Dies erhöht die
        Sicherheit, dass Diamant und Zertifikat wirklich zusammengehören.
      </p>
    </MoreExpander>
  </Col>
        </Row>
      </Container>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      