import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Wrapper mdxType="Wrapper">
      <hr></hr>
      <h1>{`Materialien`}</h1>
      <h3 {...{
        "id": "gelbgold"
      }}>{`Gelbgold`}</h3>
      <p>{`  Das klassische Edelmetall für Trauringe ist Gelbgold. Sie können wählen zwischen den Legierungen 333/000,
585/000 und 750/000. Die Unterschiede erklären wir Ihnen gerne in unserem Trauringstudio.`}</p>
      <h3 {...{
        "id": "weissgold"
      }}>{`Weissgold`}</h3>
      <p>{`  Weißgold kommt in der Natur nicht vor. Es entsteht durch eine Legierung von Gelbgold und anderen weißen
Materialien. Da lediglich der Goldanteil (333, 585, 750 von tausend Anteilen) auf dem Schmuckstück
punziert werden muss, resultieren daraus große Qualitätsunterschiede, je nachdem mit welchen anderen
Metallen das Gold legiert wird. Nickel kommt bei uns nicht vor! So wollen wir Reklamationen Ihrerseits
vermeiden. Zu beachten ist allerdings, dass Weißgold erst durch eine Rhodinierung seine helle weiße Farbe
erlangt. Dies ist eine Beschichtung mit Rhodium, die je nach Beanspruchung von Zeit zu Zeit erneuert
werden muss.`}</p>
      <h3 {...{
        "id": "graugold"
      }}>{`Graugold`}</h3>
      <p>{`  Graugold ist ebenso wie Weißgold eine Legierung aus Gold und anderen Metallen. Die graue Farbe entsteht
durch die Beimischung von Palladium (18-20%), was die Ringe farbecht werden lässt und allergische
Reaktionen minimiert.`}</p>
      <h3 {...{
        "id": "rose--und-rotgold"
      }}>{`Rosé- und Rotgold`}</h3>
      <p>{`  Durch die Beimischung von Kupfer in verschieden hohen Anteilen entstehen Rosé- oder Rotgoldringe. Gerade
in der Kombination mit Weißgold sind diese Trauringe bei uns zur Zeit sehr gefragt. Wir zeigen Ihnen gerne
viele verschiedene Muster in unserem Trauringstudio.`}</p>
      <h3 {...{
        "id": "platin"
      }}>{`Platin`}</h3>
      <p>{`  Platin ist das edelste aller Edelmetalle. Es ist das ideale Metall für Trauringe, da es relativ kratzfest
ist, die Diamanten besonders gut einschließt und einen äußerst geringen Abrieb hat. Dadurch werden Ihre
Trauringe nicht dünner. Platin hat eine helle, weiße Farbe, die sich im Laufe des Lebens nicht verändert.
Dies alles gilt nur für die Legierung von 950/000 Platin. Alternativ bieten wir Ihnen aber auch andere
Platin-Legierungen an.`}</p>
      <h3 {...{
        "id": "palladium"
      }}>{`Palladium`}</h3>
      <p>{`  Palladium ist im Schmuckbereich ein relativ neues Material. Aber die Nachfrage nach Palladium steigt, da
es viele positive Eigenschaften hat:`}</p>
      <ul>
        <li parentName="ul">{`Palladium verändert seine Farbe nicht`}</li>
        <li parentName="ul">{`Palladium ist beständiger gegen Abrieb als Weißgold`}</li>
        <li parentName="ul">{`Palladium ist leichter als Gold und deshalb preislich oft günstiger`}</li>
      </ul>
      <h3 {...{
        "id": "silber-und-palladiumsilber"
      }}>{`Silber und Palladiumsilber`}</h3>
      <p>{`  Silber ist als Trauring-Material nur bedingt geeignet, da es sehr weich ist und die Oberfläche recht
schnell verkratzt. Als Alternative bieten wir Ihnen eine Legierung aus Silber mit Palladium an, deren
Eigenschaften sehr gut dazu geeignet sind, einen langlebigen Trauring herzustellen. Diese Ringe sind
deutlich preiswerter als Weißgold oder Palladium. Viele Trauringmuster können wir Ihnen in dieser neuen
Legierung anbieten.`}</p>
      <h3 {...{
        "id": "edelstahl-und-titan"
      }}>{`Edelstahl und Titan`}</h3>
      <p>{`  Auch Ringe aus Edelstahl oder Titan sind nur bedingt als Trauring geeignet. Sie sind sehr viel härter als
Ringe aus Gold, Silber, Platin oder Palladium. Dadurch sind die Tragespuren relativ gering. Allerdings
lassen sie sich deshalb nicht mehr nachträglich in der Größe verändern. Und Ihre Hände werden sich im
Laufe ihres Lebens verändern. Dann passt ihr Ring nicht mehr an ihren Finger. Das sollten Sie beim Kauf
dieser Ringe beachten.`}</p>
      <h3 {...{
        "id": "damaszener-stahl"
      }}>{`Damaszener Stahl`}</h3>
      <p>{`  Trauringe aus Damaszener Stahl sind Unikate, kein Ring ist wie der andere. Durch das Schmieden mehrerer
Schichten unterschiedlichen Stahls in sich abwechselnden Lagen entstehen einzigartige Muster. Als
Untermaterial sind Silber, Palladium oder Gold möglich.`}</p>
      <h3 {...{
        "id": "tantal"
      }}>{`Tantal`}</h3>
      <p>{`  Tantal ist eines der seltensten Elemente unseres Sonnensystems. Der einzigartige, anthrazit-dunkle Farbton
macht das Metall unverkennbar. Es ist 5mal härter als Platin und damit sehr oberflächenbeständig gegenüber
den alltäglichen äußeren Einflüssen. In seiner Verwendung im Schmuckbereich ist Tantal zu 100%
unbedenklich und hautverträglich. Tantal ist ganz klar etwas Besonderes - selten, langlebig, edel.`}</p>
      <h3 {...{
        "id": "carbon"
      }}>{`Carbon`}</h3>
      <p>{`  Carbon ist ein Kunststoff, der sehr geschmeidig und dennoch robust ist. Carbon ist leicht und somit
angenehm zu tragen. Seine Farbe ist einzigartig. Wir führen Carbon-Ringe in tiefsattem schwarz, aber auch
in grau. Carbon kann mit den verschiedensten Edelmetallen verarbeitet werden.`}</p>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      